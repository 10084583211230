import React, { FC, memo, useMemo } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { removeNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { successRegisterOfflineEventNoticeId } from '../../constants'
import data from '../../data.json'
import { IEventsRegisterNotice } from '../../types'

/** Нотис при успешной регистрации на мероприятие (оффлайн) */
const EventsPageRegisterOfflineEventNotice: FC<IEventsRegisterNotice> = (props) => {
  const { id, open, isEventStarted } = props

  const noticeNata = useMemo(() => data.successRegister.offline, [])

  return (
    <Notice
      id={successRegisterOfflineEventNoticeId + id}
      hideProgress
      open={open}
      color='blue'
      onClose={() => {
        removeNoticeCookie({ id: successRegisterOfflineEventNoticeId + id })
      }}
    >
      <Grid spacing='medium' nowrap alignItems='flex-end'>
        <GridCol width='auto' shrink='initial'>
          <Heading h3={noticeNata.title} spacing='xxsmall' />

          {isEventStarted ? (
            <Text>{noticeNata.descriptionForStarted}</Text>
          ) : (
            <Text>{noticeNata.description}</Text>
          )}
        </GridCol>
      </Grid>
    </Notice>
  )
}

export default memo(EventsPageRegisterOfflineEventNotice)
