import React, { FC, memo, Fragment, useCallback } from 'react'
import { useRouter } from 'next/router'

import { useAllowedChannels } from '@ucheba/utils/hooks/useAllowedChannels'
import { EMessengerChannel, EMessengerType } from '@ucheba/store/profile/types'
import { Banner } from '../../Banner/touch'
import { IEventsListProps } from '../types'
import { EventsListItem } from './EventsListItem'

import SC from '../base/EventsList.styles'
import { pathsForBanner } from '../contants'
import { useFinishedEvents } from '../bll'

const { Block } = SC

/** Список событий */
const EventsList: FC<IEventsListProps> = (props) => {
  const { isBanner = false, href, needVk, linkReg, items, params, onClickAction } = props
  const router = useRouter()
  const isPathForBanner = pathsForBanner.includes(router.pathname)

  const isFirstFinishedEvent = useFinishedEvents(items)

  const isAllowedMess = useAllowedChannels([
    EMessengerChannel.vk_ucheba,
    EMessengerChannel.telegram_ucheba,
  ])

  const showBanner = useCallback(
    (index: number) => {
      if (isBanner && items && items?.length < 3) {
        return items.length - 1 === index
      }
      if (isBanner && items && items?.length > 9) {
        return index === 2 || index === 9
      }
      if (isBanner && items) {
        return index === 2
      }
      return false
    },
    [items, isBanner]
  )

  return (
    <Block>
      {items.map((event, index, array) => {
        const isDivider = index !== 2 && isBanner

        if (array.length < 3 && index === array.length - 1 && isPathForBanner) {
          return (
            <Fragment key={event.id}>
              <EventsListItem
                isDivider
                {...event}
                onClickAction={onClickAction}
                pathname={href}
                needVk={needVk}
                isFinishedLine={isFirstFinishedEvent(index)}
                linkReg={linkReg}
                isAllowedMess={isAllowedMess}
              />
              {showBanner(index) && (
                <li>
                  <Banner
                    id={5}
                    extraParams={params}
                    spacing={{
                      top: 'xxsmall',
                      bottom: 'xxsmall',
                    }}
                  />
                </li>
              )}
            </Fragment>
          )
        }

        if (index === 2 && isPathForBanner) {
          return (
            <Fragment key={event.id}>
              <EventsListItem
                isDivider
                {...event}
                onClickAction={onClickAction}
                pathname={href}
                needVk={needVk}
                isFinishedLine={isFirstFinishedEvent(index)}
                linkReg={linkReg}
                isAllowedMess={isAllowedMess}
              />

              {showBanner(index) && (
                <li>
                  <Banner
                    id={5}
                    extraParams={params}
                    spacing={{
                      top: 'xxsmall',
                      bottom: 'xxsmall',
                    }}
                  />
                </li>
              )}
            </Fragment>
          )
        }

        return (
          <Fragment key={event.id}>
            <EventsListItem
              isDivider
              {...event}
              onClickAction={onClickAction}
              pathname={href}
              needVk={needVk}
              isFinishedLine={isFirstFinishedEvent(index)}
              linkReg={linkReg}
              isAllowedMess={isAllowedMess}
            />

            {showBanner(index) && (
              <li>
                <Banner
                  id={5}
                  extraParams={params}
                  spacing={{
                    top: 'xxsmall',
                    bottom: 'xxsmall',
                  }}
                />
              </li>
            )}
          </Fragment>
        )
      })}

      {isBanner && items.length === 0 && (
        <li>
          <Banner
            id={5}
            extraParams={params}
            spacing={{
              top: 'xxsmall',
              bottom: 'xxsmall',
            }}
          />
        </li>
      )}
    </Block>
  )
}

export { EventsList }
export default memo(EventsList)
