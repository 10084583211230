import React, { FC, memo, useMemo } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { removeNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { successRegisterOnlineStartedEventNoticeId } from '../../constants'
import data from '../../data.json'
import { IEventsRegisterNotice } from '../../types'
import { EventsListItemButton } from '../../../../../EventsList/touch/EventsListItemButton'
import { EEventRegisteredStatus } from '../../../../../EventsList/types'

/** Нотис при успешной регистрации на мероприятие (не стартовавшее) */
const EventsRegisterStartedEventNotice: FC<IEventsRegisterNotice> = (props) => {
  const { id, href, open } = props

  const noticeNata = useMemo(() => data.successRegister.onlineNow, [])

  return (
    <Notice
      id={successRegisterOnlineStartedEventNoticeId + id}
      hideProgress
      open={open}
      color='blue'
      onClose={() => {
        removeNoticeCookie({ id: successRegisterOnlineStartedEventNoticeId + id })
      }}
    >
      <Grid spacing='medium' nowrap alignItems='flex-end'>
        <GridCol width='auto' shrink='initial'>
          <Heading h3={noticeNata.title} spacing='medium' />

          <EventsListItemButton
            registeredStatus={EEventRegisteredStatus.watchBroadcast}
            color='gold'
            href={href}
          >
            {data.successRegister.onlineNow.buttonText}
          </EventsListItemButton>
        </GridCol>
      </Grid>
    </Notice>
  )
}

export default memo(EventsRegisterStartedEventNotice)
