import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import Link from 'next/link'
import { ExternalLogo } from '@ucheba/ui/components/ExternalLogo/touch'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Logo } from '@ucheba/ui/components/Logo/touch'
import { mathVars } from '@ucheba/utils/helpers/styles/mixins'
import PlayIcon from '@ucheba/ui/icons/icon__circled-play.svg'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/desktop'
import { Box } from '@ucheba/ui/components/Box/touch'
import { authSelectors } from '@ucheba/store/auth'
import { useSelector } from 'react-redux'
import { EEventFormat, IEventsListItemProps } from '../types'
import SC from '../base/EventsListItem.styles'
import { useEventsListItemCore, useEventsListItemDate } from '../bll'
import { EventsListItemButton } from '../desktop/EventsListItemButton'
import EventsPageRegisterNotStartedEventNoticeMobile from '../../EventPages/components/EventNotices/EventsRegisterNotStartedEventNotice/touch'
import EventsPageRegisterStartedEventNoticeMobile from '../../EventPages/components/EventNotices/EventsRegisterStartedEventNotice/touch'
import EventsPageRegisterOfflineEventNoticeMobile from '../../EventPages/components/EventNotices/EventsRegisterOfflineEventNotice/touch'
import ProfMarathonRegisterNotStartedEventNotice from '../../../pages/events/prof-marathon/calendar/index/touch/ProfMarathonRegisterNotStartedEventNotice'
import { nameUcheba } from '../contants'
import { forAbiturients } from '../../../routs'
import EventNoticeNotAllowedMessage from '../../EventPages/components/EventNotices/EventNoticeNotAllowedMessage/touch'
import ParentFormDialog from '../../ParentFormDialog/touch'
import ParentFormDialogSuccess from '../../ParentFormDialog/touch/ParentFormDialogSuccess'
import EventBrandingUchebaRegisration from '../../EventPages/components/EventBrandingUchebaRegisration/touch'
import PhoneConfirmDialog from '../../RequestForm/touch/PhoneConfirmDialog'
import { useEventBrandingUchebaReg } from '../../EventPages/components/EventBrandingUchebaRegisration/bll'

const { Block: BlockSC } = SC

const Block = styled(BlockSC)`
  padding: ${({ isFinished }) =>
      isFinished ? mathVars(sv.spacing.large, '+', 8) : sv.spacing.large}
    0;

  border-top: none;
  border-top: 1px solid ${({ isDivider }) => (isDivider ? sv.color.gray9 : 'none')};
`

/** Событие */

const EventsListItem: FC<IEventsListItemProps> = (props) => {
  const {
    isDivider = true,
    name,
    streamUrl,
    id,
    pathname,
    formats,
    needVk,
    isFinished,
    isFinishedLine,
    linkReg,
    onClickAction,
    exam,
    hasBranding,
    rubric,
  } = props

  const onClick = (): void => {
    if (onClickAction) {
      onClickAction(id)
    }
  }

  const {
    href,
    institution,
    description,
    buttonData,
    onClickRegistration,
    registersPending,
    notices,
    messengersDialog,
    isEventStartedOrHour,
  } = useEventsListItemCore({ ...props, pathname })

  const { format, date } = useEventsListItemDate(props)

  const isHybridEvent = useMemo(() => {
    return (
      formats.some((val) => val.value === EEventFormat.online) &&
      formats.some((val) => val.value === EEventFormat.offline)
    )
  }, [formats])

  const [open, setOpen] = useState(false)

  const isAuth = useSelector(authSelectors.isAuth)

  const onClickBrandRegistration = useCallback(async () => {
    if (hasBranding && !isAuth) {
      setOpen(true)
    } else {
      await onClickRegistration()
    }
  }, [hasBranding, isAuth, onClickRegistration])

  const {
    phone,
    needConfirmPhone,
    onPhoneConfirmClose,
    onPhoneConfirmSubmit,
    isLoading,
    notAllowedMessengerNotice,
  } = useEventBrandingUchebaReg({
    id: props?.id,
    rubricId: rubric?.id,
  })

  return (
    <Block isDivider={isDivider} isFinished={isFinishedLine} data-test-id='event'>
      {needVk ? (
        <ProfMarathonRegisterNotStartedEventNotice
          id={id}
          open={!!notices.onlineEventNotStartedNotice.open}
          isHybrid={isHybridEvent}
          needVk={needVk}
          linkReg={linkReg}
        />
      ) : (
        <EventsPageRegisterNotStartedEventNoticeMobile
          id={id}
          open={!!notices.onlineEventNotStartedNotice.open}
          isHybrid={isHybridEvent}
          needVk={needVk}
        />
      )}

      <EventsPageRegisterStartedEventNoticeMobile
        id={id}
        href={streamUrl}
        open={!!notices.onlineEventStartedNotice.open}
        needVk={needVk}
      />

      <EventsPageRegisterOfflineEventNoticeMobile
        id={id}
        open={notices.offlineEventNotice.open}
        isEventStarted={isEventStartedOrHour}
      />

      <EventNoticeNotAllowedMessage
        id={id}
        open={!!notices.notAllowedMessegerNotice.open || !!notAllowedMessengerNotice.open}
        isHybrid={isHybridEvent}
        isOnline={formats.some((val) => val.value === EEventFormat.online)}
        onClose={
          notices.notAllowedMessegerNotice.removeNotice ||
          notAllowedMessengerNotice.removeNotice
        }
      />

      {institution && institution.logo ? (
        <>
          <ExternalLogo
            logo={institution.logo}
            href={institution.href}
            maxWidth='120px'
            maxHeight='80px'
            height='64px'
            alt={institution.name}
          />

          <Spacing spacing='xsmall' />
        </>
      ) : (
        <>
          <Link href={forAbiturients.ege.path} passHref>
            <Logo width='120px' color='blue' isSquare />
          </Link>

          <Spacing spacing='xsmall' />
        </>
      )}

      {isFinished && (
        <>
          <Text fontWeight='semibold' color='gray40'>
            Вебинар закончился
          </Text>

          <Spacing spacing='usmall' />
        </>
      )}

      {(date || format) && (
        <>
          <Text fontWeight='semibold' html={false}>
            {date}

            {date && format && (
              <Text color='gray25' as='span'>
                {' • '}
              </Text>
            )}

            {format}
          </Text>

          <Spacing spacing='usmall' />
        </>
      )}

      {institution ? (
        <>
          <Text fontWeight='semibold' html={false}>
            <Link href={institution.href} passHref>
              {institution.name}
            </Link>

            {institution?.location && (
              <Text color='gray60' as='span'>
                {' • '}
              </Text>
            )}

            {institution?.location.name}
          </Text>

          <Spacing spacing='usmall' />
        </>
      ) : (
        <>
          <Text fontWeight='semibold' html={false}>
            <Link href={forAbiturients.ege.path} passHref>
              {nameUcheba}
            </Link>
          </Text>

          <Spacing spacing='usmall' />
        </>
      )}

      <Link href={href}>
        <Text fontWeight='bold' color='blue' fontSize='h3' lineHeight='h3'>
          {name}
        </Text>
      </Link>

      <Spacing spacing='usmall' />

      {description && <Text>{description}</Text>}

      <Spacing spacing='small' />

      {isFinished ? (
        <Button
          href={href}
          StartIcon={<PlayIcon width='20px' height='20px' />}
          color='blue'
        >
          Смотреть трансляцию
        </Button>
      ) : (
        <EventsListItemButton
          onClick={onClickAction ? onClick : onClickBrandRegistration}
          progress={registersPending}
          href={streamUrl}
          registeredStatus={buttonData.eventRegisteredStatus}
        >
          {buttonData.buttonText}
        </EventsListItemButton>
      )}

      <ParentFormDialog id={id} exam={exam} />

      <ParentFormDialogSuccess
        open={messengersDialog.isActive}
        onClose={messengersDialog.closeDialog}
        isInstitution={!!institution}
        isNeedVk={!(rubric && rubric?.id === 5)}
      />

      <BottomSheet open={open} toggleOpen={setOpen}>
        <Box spacing={['small', 'small']}>
          <EventBrandingUchebaRegisration
            id={id}
            onAfterSubmitForm={() => setOpen(false)}
          />
        </Box>
      </BottomSheet>

      {phone && needConfirmPhone ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
    </Block>
  )
}

export { EventsListItem }
export default memo(EventsListItem)
