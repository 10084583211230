import React, { FC, memo, useMemo } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { removeNoticeCookie } from '@ucheba/ui/components/Notice/bll'
import { successRegisterOnlineNotStartedEventNoticeId } from '../../constants'
import data from '../../data.json'
import { IEventsRegisterOnlineNotice } from '../../types'

/** Нотис при успешной регистрации на мероприятие (не стартовавшее) */
const EventsRegisterNotStartedEventNotice: FC<IEventsRegisterOnlineNotice> = (props) => {
  const { id, open, isHybrid } = props
  const noticeNata = useMemo(() => data.successRegister.onlineLater, [])

  return (
    <Notice
      id={successRegisterOnlineNotStartedEventNoticeId + id}
      hideProgress
      open={open}
      color='blue'
      onClose={() => {
        removeNoticeCookie({ id: successRegisterOnlineNotStartedEventNoticeId + id })
      }}
    >
      <Grid spacing='medium' nowrap alignItems='flex-end'>
        <GridCol width='auto' shrink='initial'>
          <Heading h3={noticeNata.title} spacing='xxsmall' />

          <Text>{!isHybrid ? noticeNata.description : noticeNata.hybridDescription}</Text>
        </GridCol>
      </Grid>
    </Notice>
  )
}

export default memo(EventsRegisterNotStartedEventNotice)
